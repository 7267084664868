import classNames from "classnames"
import React from "react"

import { Color } from "../../../../constants/V2/color"
import Arrow from "../../../elements/V2/Arrow"
import TextButton from "../../../elements/V2/Buttons/TextButton"
import Typography from "../../../elements/V2/Typography"

type Props = {
  id: string
  icon: string
  text: string
  dropdownText: string
  dropdownLinkText: string
  dropdownLinkUrl: string
  mode: "light" | "dark"
}

// Note: this is meant to work as pure html and css using react-dom renderToString
const InteractiveParagraphDropdown = ({
  id,
  icon,
  text,
  dropdownText,
  dropdownLinkText,
  dropdownLinkUrl,
  mode,
}: Props) => {
  const checkbox_id = `interactive-paragraph-dropdown-${id}`

  return (
    <span
      className={classNames(
        "group/interactive-paragraph relative z-10 inline-block align-middle transition-colors duration-300 ease-in-out focus:z-20 has-[:checked]:z-20 has-[:checked]:text-white-v2 md-v2:hover:text-white-v2",
        mode === "light" ? "text-black-v2" : "text-blue-v2"
      )}
    >
      {/* Hidden checkbox to control toggle state of dropdown */}
      <input type="checkbox" className="absolute hidden" id={checkbox_id} />

      <label
        htmlFor={checkbox_id}
        className="py-xs-v2 relative z-10 flex cursor-pointer items-center px-16-v2 !leading-none lg-v2:px-20-v2"
      >
        <img
          src={icon}
          width={24}
          height={24}
          className="mr-8-v2 h-24-v2 w-24-v2 opacity-100 transition-all duration-300 ease-in-out group-has-[:checked]/interactive-paragraph:mr-0 group-has-[:checked]/interactive-paragraph:w-0 group-has-[:checked]/interactive-paragraph:opacity-0"
        />

        {text}

        <img
          src={"/icons/V2/icon-close-white.svg"}
          width={24}
          height={24}
          className="ml-0 h-24-v2 max-w-0 transition-all duration-300 ease-in-out group-has-[:checked]/interactive-paragraph:ml-8-v2 group-has-[:checked]/interactive-paragraph:max-w-24-v2"
        />
      </label>

      <div
        className={classNames(
          "rounded-lg-v2 py-2xs-v2 absolute left-0 top-0 flex max-h-40-v2 w-full flex-col overflow-hidden px-16-v2 pb-16-v2 transition-all duration-300 ease-in-out group-has-[:checked]/interactive-paragraph:max-h-[300px] md-v2:pb-24-v2 lg-v2:max-h-60-v2 lg-v2:px-20-v2",
          mode === "light"
            ? "bg-light-blue-v2 group-has-[:checked]/interactive-paragraph:bg-charcoal-v2 md-v2:group-hover/interactive-paragraph:bg-charcoal-v2"
            : "bg-charcoal-v2 group-has-[:checked]/interactive-paragraph:bg-blue-v2 md-v2:group-hover/interactive-paragraph:bg-blue-v2"
        )}
      >
        <Typography
          text={dropdownText}
          font="grotesk"
          size="body-lg"
          weight="book"
          className="mt-40-v2 lg-v2:mt-56-v2"
          color={Color.White}
        />

        <TextButton
          color={mode === "light" ? Color.Blue : Color.White}
          style="icon"
          className="mt-xs-v2"
          text={dropdownLinkText}
          linkUrl={dropdownLinkUrl}
          icon={<Arrow />}
        />
      </div>
    </span>
  )
}

export default InteractiveParagraphDropdown
